import axios from 'axios';

function showLoading() {
  document.getElementById('loading-overlay').classList.add('show');
}

function hideLoading() {
  document.getElementById('loading-overlay').classList.remove('show');
}

let activeRequests = 0;
const TIMEOUT = 1000000;

const onRequestSuccess = config => {
  activeRequests += 1;
  if (activeRequests === 1) {
    showLoading();
  }

  const token = localStorage.getItem('jhi-authenticationToken') || sessionStorage.getItem('jhi-authenticationToken');
  if (token) {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.timeout = TIMEOUT;
  config.url = `${SERVER_API_URL}${config.url}`;
  return config;
};

const setupAxiosInterceptors = (onUnauthenticated, onServerError) => {
  const onRequestError = (error) => {
    activeRequests -= 1;
    if (activeRequests === 0) {
      hideLoading();
    }
    return Promise.reject(error);
  };

  const onResponseSuccess = res => {
    activeRequests -= 1;
    if (activeRequests === 0) {
      hideLoading();
    }
    return res;
  };

  const onResponseError = err => {
    const status = err.status || err.response.status;
    if (status === 403 || status === 401) {
      return onUnauthenticated(err);
    }
    if (status >= 500) {
      return onServerError(err);
    }

    activeRequests -= 1;
    if (activeRequests === 0) {
      hideLoading();
    }

    return Promise.reject(err);
  };

  if (axios.interceptors) {
    axios.interceptors.request.use(onRequestSuccess, onRequestError);
    axios.interceptors.response.use(onResponseSuccess, onResponseError);
  }
};

export {onRequestSuccess, setupAxiosInterceptors};
